import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CssVariablesService } from '@callrail/looky/ui';

import { JSONRequest } from '../../interfaces/json-request';
import { UserModel } from '../../models/user.model';
import { CurrentContextService } from '../current-context/current-context.service';

@Injectable({
  providedIn: 'root',
})
export class BackstageService {
  public isModalOpen$ = new BehaviorSubject(false);
  public canShow$ = this.context?.user?.pipe(map((user) => this.canShow(user)));
  public featuredData: { [key: string]: any } = {};
  public requests: { [key: string]: JSONRequest[] | JSONRequest } = {};
  public isWlToggledOn;

  constructor(
    private context: CurrentContextService,
    private cssVariablesService: CssVariablesService
  ) {}

  public get isModalOpen(): boolean {
    return this.isModalOpen$.getValue();
  }

  public set isModalOpen(val: boolean) {
    this.isModalOpen$.next(val);
  }

  public canShow(user: UserModel): boolean {
    // eslint-disable-next-line no-console
    console.log('.');
    this.isWlToggledOn = this.isWlEnv;
    const isProdBackendService = this.context.env?.environment === 'production';
    if (!isProdBackendService) {
      return true;
    }
    return user?.superadmin;
  }

  public toggleWhiteLabel() {
    if (!this.isWlToggledOn) {
      this.cssVariablesService.buildWLTheme();
    } else {
      this.cssVariablesService.buildCallrailTheme();
    }
    this.isWlToggledOn = !this.isWlToggledOn;
  }

  private get isWlEnv(): boolean {
    return !!(this.context.env && this.context.env.white_label);
  }
}
